import {
  Autocomplete,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import ButtonCustom from 'components/ButtonCustom';
import { CustomTableFontCell } from 'components/CustomTableFontCell';
import { StyledTableSortLabel } from 'components/TableSortedLabel';
import { TypographyLocale } from 'components/TypographyLocale';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { adminTopUp } from 'services/business-setting-service';
import shortid from 'shortid';
import { USER_ROLES } from 'utils/constants';
import { IAdminTopUp, Order } from 'utils/contracts';
import { package_options } from 'utils/Formatter';
import { calcTotalAccountPoints, getErrorHtmlContent } from 'utils/utils';
import Transaction from './Transaction';

let DEFAULT_ORDER = 'desc',
  DEFAULT_ORDER_BY = 'createdAt';

const textFieldStyles = {
  '& .MuiInputBase-root': {
    height: '50px', // Custom height
    padding: '20px 0px', // Adjust padding as needed
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(12px, 14px) scale(1)', // Adjust label position
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(12px, -10px) scale(0.75)',
  },
};

const BusinessCredits = ({ organizations, refetch, mode }: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>(DEFAULT_ORDER as Order);
  const [orderBy, setOrderBy] = useState<string>(DEFAULT_ORDER_BY);
  const [creditRows, setCreditRows] = useState<any>([]);
  const [packageSection, setPackage] = useState('');
  const [sum, setSum] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [credits, setCredits] = useState<string | undefined>(undefined);
  const { role } = useContext(UseAuthContext);
  const [totalPoints, setTotalPoints] = useState(0);


  const mutation = useMutation(adminTopUp, {
    onSuccess: () => {
      enqueueSnackbar(t('BUSINESS_ADMIN_CREDIT_ADD_SUCESS'), {
        variant: 'success',
      });
      refetch();
    },
    onError: (error: any) => {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      console.log('onError', error);
    },
  });

  const totalCredits = useMemo(() => {
    if (mode === 'create') return 0;
    return calcTotalAccountPoints(organizations);
  }, [organizations?.transactions, mode]);

  const addCreditsHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (mode === 'create') {
      enqueueSnackbar(t('BUSINESS_ADMIN_CREDIT_CREATE_FIRST'), {
        variant: 'warning',
      });
      return;
    }
    setisLoading(true);
    const topUpData: IAdminTopUp = {
      ProdQuantity: 1,
      OrganizationId: organizations.id,
      ProductDesc: description,
      TransactionId: shortid.generate(),
      ProductID: packageSection,
    };
    if (packageSection === 'CUSTOM') {
      topUpData.suminfull = sum;
      topUpData.TotalPoints = credits;
    }
    mutation.mutate(topUpData);
    setisLoading(false);
  };

  const disableInputs = useMemo(() => {
    if (mode === 'create') {
      return true;
    }
    
    if (packageSection === 'CUSTOM') {
      setDescription('SPECIAL');
      return false;
    } else {
      setDescription('');
      return true;
    }
  }, [packageSection, mode]);

  useEffect(() => {
    if (mode == 'create') {
      setCreditRows([]);
      setPage(0);
      setRowsPerPage(10);
      setOrder(DEFAULT_ORDER as Order);
      setOrderBy(DEFAULT_ORDER_BY);
      return;
    }


    if (organizations?.transactions) {
      const newCreditRows = [];
      for (let property in organizations.transactions) {
        if (organizations.transactions.hasOwnProperty(property)) {
          const transaction = organizations.transactions[property];
          if (transaction.status === 'success') {
            newCreditRows.push(transaction);
          }
        }
      }
      setCreditRows(newCreditRows);
    }
  }, [organizations,refetch, mode]);




  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property: keyof typeof creditRows[0]) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as string);
  };

  const sortedCreditRows = useMemo(() => {
    return creditRows.sort((a, b) => {
      if (orderBy === 'status') {
        if (a.type !== b.type) {
          return order === 'asc' 
            ? (a.type === 'CREDIT' ? -1 : 1)
            : (a.type === 'CREDIT' ? 1 : -1);
        }
        if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
      } else {
        if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
  }, [creditRows, order, orderBy]);

  return (
    <Box>
      <Box className='business_credits_header'>
        <TypographyLocale variant='h2' fontWeight='bold'>
          <span>{totalCredits} </span>
          <span>{t('EDIT_BUSINESS_HEADER_BALANCE')}</span>
        </TypographyLocale>
        {role === USER_ROLES.ADMIN && mode !== 'create' && (
          <form>           
            <Box className="business_credits_header_valid">
              <Box>
                <Autocomplete
                  clearIcon={<></>}
                  options={package_options}
                  getOptionLabel={(option: any) => option.value}
                  onChange={(_, value: any) => {
                    setPackage(value?.value);
                  }}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      sx={{ ...textFieldStyles, width: "220px" }}
                      {...params}
                      label={t("ADMIN_ADD_BUSINESS_CREDIT_PACKAGE_SELECT")}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        sx: { height: "50px" },
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <TextField
                  name="sum"
                  label={t("ADMIN_ADD_BUSINESS_CREDIT_SUM")}
                  type="text"
                  placeholder={t("ADMIN_ADD_BUSINESS_CREDIT_SUM")}
                  value={sum}
                  onChange={(e) => setSum(e.target.value)}
                  disabled={disableInputs}
                  sx={textFieldStyles}
                />
              </Box>
              <Box>
                <TextField
                  name="credit"
                  label={t("ADMIN_ADD_BUSINESS_CREDIT_POINTS")}
                  type="text"
                  size='small'
                  placeholder={t("ADMIN_ADD_BUSINESS_CREDIT_POINTS")}
                  value={credits}
                  onChange={(e) => setCredits(e.target.value)}
                  disabled={disableInputs}
                  sx={textFieldStyles}
                />
              </Box>
              <Box>
                <TextField
                  name="description"
                  label={t("ADMIN_ADD_BUSINESS_CREDIT_DESCRIPTION")}
                  type="text"
                  placeholder={t("ADMIN_ADD_BUSINESS_CREDIT_DESCRIPTION")}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={disableInputs}
                  sx={textFieldStyles}
                />
              </Box>
              <Box>
                <ButtonCustom
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={addCreditsHandler}
                >
                  {t("ADMIN_ADD_BUSINESS_CREDIT_BUTTON")}
                </ButtonCustom>
              </Box>
            </Box>
        </form>)}
        {mode !== 'create' && (
          <>
            <Table size="small">
              <TableHead>
                <CustomTableFontCell align='center' width="100px">
                    <StyledTableSortLabel
                      active={orderBy === "status"}
                      direction={orderBy === "status" ? order : "asc"}
                      onClick={() => createSortHandler("status")}
                    >
                      {t('BUSINESS_SETTING_TRANSACTION_STATUS')}                  
                      {orderBy === "status" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
                 
                  <CustomTableFontCell width="180px">
                    <StyledTableSortLabel
                      active={orderBy === "createdAt"}
                      direction={orderBy === "createdAt" ? order : "asc"}
                      onClick={() => createSortHandler("createdAt")}
                    >
                      {t('BUSINESS_SETTING_TRANSACTION_DATE')}                  
                      {orderBy === "createdAt" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
                  <CustomTableFontCell width="150px">
                    <StyledTableSortLabel
                      active={orderBy === "user"}
                      direction={orderBy === "user" ? order : "asc"}
                      onClick={() => createSortHandler("user")}
                    >
                      {t('BUSINESS_SETTING_TRANSACTION_BY')}
                      {orderBy === "user" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell >
                  <CustomTableFontCell width="150px" >
                    <StyledTableSortLabel
                      active={orderBy === "project"}
                      direction={orderBy === "project" ? order : "asc"}
                      onClick={() => createSortHandler("project")}
                    >
                      {t('EDIT_BUSINESS_TABLE_FOR')}
                      {orderBy === "project" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
                  <CustomTableFontCell  width="150px">
                    <StyledTableSortLabel
                      active={orderBy === "points"}
                      direction={orderBy === "points" ? order : "asc"}
                      onClick={() => createSortHandler("points")}
                    >
                      {t('BUSINESS_SETTING_TRANSACTION_CREDITS')}
                      {orderBy === "points" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
              </TableHead>
              <TableBody>
                {sortedCreditRows &&
                  sortedCreditRows.length > 0 &&
                  sortedCreditRows
                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((transaction) => {
                      return <Transaction 
                      key={transaction.id}
                      transaction={transaction}
                    />
                    })}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ width: "100%" }}
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={creditRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
              labelDisplayedRows={({ from, to, count }) => t('MUI_PAGINATION_RANGE', { from, to, count })}
              getItemAriaLabel={(type) => t(`MUI_PAGINATION_${type.toUpperCase()}_PAGE`)}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default BusinessCredits;
