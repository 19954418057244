import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Spinner from 'components/Spinner';
import { TypographyLocale } from 'components/TypographyLocale';
import UseAuthContext from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { GetUserOrganization } from 'services/auth-service';
import BusinessContact from './BusinessContact';
import BusinessCredits from './BusinessCredits';
import BusinessSetting from './BusinessSetting';
import BusinessStaff from './BusinessStaff';

const BusinessAccountPage = ({mode}) => {
  const [value, setValue] = React.useState('SETTING');
  const { t } = useTranslation();
  const { language } = useContext(UseLanguageContext);
  const navigate = useNavigate();

  const { setOrganisation, organizations, role } =
    React.useContext(UseAuthContext);
  const { businessId } = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { data, isLoading, refetch } = useQuery(
    ['GetOrganisationByID', businessId ? businessId : organizations?.id],
    async () => {
      return await GetUserOrganization(
        businessId ? businessId : organizations?.id ?? ''
      );
    },
    {
      enabled: mode !== 'create' && (organizations?.id !== undefined || businessId !== undefined),
      refetchOnWindowFocus: false,
      staleTime: 0 // For
    }
  );

  useEffect(() => {
    if (data) {
      setOrganisation(data.data);
    }
  }, [data]);

  if (isLoading && mode !== 'create') {
    return <Spinner></Spinner>;
  }


  const OrganisationCallback = async (organisationID: any) => {
     const OrganisationData = await GetUserOrganization(organisationID);
     setOrganisation(OrganisationData.data);
    // Better way to navigate once we've some info from Admin regarding new business account
    navigate(`/edit/business/${organisationID}`);
  };

  return (
    <Box sx={{ }}>
      <Box sx={{ height: "144px", mb: "22px", display:"flex", alignItems: "center" }}>
        <TypographyLocale
          variant='h2'
          language={language}
        >
          { t('BUSINESS_EDIT_HEADER')}
        </TypographyLocale>
      </Box>
      <Box sx={{ bgcolor: '#fff' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '30px' }}>
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label={t('BUSINESS_EDIT_Setting_HEADER')} value='SETTING' />
              <Tab label={t('BUSINESS_EDIT_CONTACT_HEADER')} value='CONTACT' disabled={mode == "create"}/>
              <Tab label={t('BUSINESS_EDIT_CREDIT_HEADER')} value='CREDITS' disabled={mode == "create"} />
              <Tab label={t('BUSINESS_EDIT_STAFF_HEADER')} value='STAFF' disabled={mode == "create"} />
            </TabList>
          </Box>
          <TabPanel value='SETTING' className='tab-content'>
            <BusinessSetting refetch={refetch} mode={mode} organisationCallback={OrganisationCallback} />
          </TabPanel>
          <TabPanel value='CONTACT' className='tab-content'>
            <BusinessContact refetch={refetch}  />
          </TabPanel>
          <TabPanel value='CREDITS' className='tab-content' >           
            <BusinessCredits
              organizations={organizations}
              refetch={refetch}
            />
          </TabPanel>
          <TabPanel value='STAFF' className='tab-content' >
            <BusinessStaff refetch={refetch}  />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default BusinessAccountPage;
