import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import { TableRow } from '@mui/material';
import CustomTableCell from 'components/CustomTableCell';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { calcCreditedPoints } from 'utils/Points';

interface TransactionProps {
  transaction: any;
  key?: string;
}

const Transaction = ({ transaction, key }: TransactionProps) => {
  const { t } = useTranslation();
  const isCredit = transaction.type !== 'DEBIT';
  const formatMessage = (transaction: any) => {
    if (!isCredit) return '';
    return `${t('BUSINESS_SETTING_TRANSACTION_PACKAGE')} ${transaction.plan_title}`;
  };

  const formatPoints = (transaction: any) => {
    if (isCredit) {
      if (transaction.monthly === true) {
        return `${calcCreditedPoints(transaction)}/${transaction.points}`;
      } 
      return transaction.points > 0 ? transaction.points : '';
    }
    return transaction.points ?? '';
  };

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      key={key}
    >
      <CustomTableCell clickable={false} align='center' width={"100px"}>
        {isCredit ? <AddRoundedIcon sx={{mr:2}}/> : <RemoveIcon sx={{mr:2}}/>}
      </CustomTableCell>
      <CustomTableCell clickable={false} width={"180px"}>
        {moment.utc(transaction.createdAt).local().format('DD/MM/YY')}{' '}
        <span className='table-time'>
          {moment.utc(transaction.createdAt).local().format('HH:mm')}
        </span>
      </CustomTableCell>
      <CustomTableCell clickable={false} width={"180px"}>
        {isCredit ? transaction.paidBy : transaction.user ?? ''}
      </CustomTableCell>
      <CustomTableCell clickable={false} width={"180px"}>
        {isCredit ? formatMessage(transaction) : transaction.project ?? ''}
      </CustomTableCell>
      <CustomTableCell clickable={false} width={"180px"}>
        {formatPoints(transaction)}
      </CustomTableCell>
    </TableRow>
  );
};

export default Transaction;