import { styled, Typography, TypographyProps } from '@mui/material';
import UseLanguageContext from 'context/LanguageContext';
import { forwardRef, useContext } from 'react'; // Ajout de l'importation

interface MyCustomTypographyProps extends TypographyProps {
  language?: string; // Exemple de prop personnalisée
}

export const TypographyCustom = styled(Typography)<{ language: string }>`
  /* @noflip */
  text-align: ${({ language }) => (language === 'he' ? 'right' : 'left')}; // Correction de la syntaxe
`;

export const TypographyLocale = forwardRef<HTMLDivElement, MyCustomTypographyProps>(
  ({ children, ...props }, ref) => {
    const { language } = useContext(UseLanguageContext); 

    return (
      <TypographyCustom language={language} ref={ref} {...props}>
        {children}
      </TypographyCustom>
    );
  }
);
