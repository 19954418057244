export const he = {
  translation:{
  ADD_BUSINESS_SETTING_SAVE_BUTTON: "שמור",
  ADD_PROJECT_CANCEL_BUTTON: "ביטול",
  ADD_PROJECT_CITY: "עיר",
  ADD_PROJECT_CLIMATE: "אקלים",
  ADD_PROJECT_DATE: "תאריך",
  ADD_PROJECT_GUSH: "גוש",
  ADD_PROJECT_HEADING_1: "מבנה חדש",
  ADD_PROJECT_HEADING_2: "מלא את כל פרטי הבניין במדויק",
  ADD_PROJECT_NAME: "שם הבניין ",
  ADD_PROJECT_PARTITION: "חלקה",
  ADD_PROJECT_SAVE_BUTTON: "שמור",
  ADD_PROJECT_STREET: "רחוב",
  ADD_PROJECT_TERRAIN: "סביבה",
  ADD_PROJECT_TYPE: "סוג",
  ADMIN_ADD_BUSINESS: "עסק חדש",
  ADMIN_ADD_BUSINESS_CREDIT: "קרדיטים",
  ADMIN_ADD_BUSINESS_CREDIT_BUTTON: "שמור עסקה",
  ADMIN_ADD_BUSINESS_CREDIT_DESCRIPTION: "תיאור",
  ADMIN_ADD_BUSINESS_CREDIT_PACKAGE_SELECT: "בחירת חבילה",
  ADMIN_ADD_BUSINESS_CREDIT_POINTS: "קרדיטים",
  ADMIN_ADD_BUSINESS_CREDIT_SUM: "סכום",
  ADMIN_DASHBOARD_ADD_ORGANISATION: "עסק חדש",
  ADMIN_DASHBOARD_DATE: "תאריך",
  ADMIN_DASHBOARD_EDIT_BUSINESS: "עריכת עסק",
  ADMIN_DASHBOARD_NAME: "שם",
  ADMIN_DASHBOARD_TITLE: "עסקים",
  ADMIN_LOGIN_GENERAL: "אירעה תקלה",
  ADMIN_LOGIN_HEADER: "Admin",
  ADMIN_LOGIN_INVALID_CREDENTIALS: "סיסמה לא נכונה",
  ADMIN_LOGIN_PASSWORD: "סיסמה",
  ADMIN_LOGIN_TEXT: "התחבר",
  ADMIN_ORGANISATION_SEARCH_PLACEHOLDER: "חיפוש",
  ADMIN_TOPUP_GENERAL: "ביצוע העסקה נכשל עקב בעיה בשרת",
  BUSINESS_ADMIN_CREDIT_ADD_SUCESS: "העסקה הצליחה",
  BUSINESS_CONTACT_INFO_UPDATE_SUCESS: "פרטי התקשרות עודכנו בהצלחה",
  BUSINESS_EDIT_CONTACT_HEADER: "פרטי התקשרות",
  BUSINESS_EDIT_CREDIT_HEADER: "קרדיטים",
  BUSINESS_EDIT_HEADER: "חשבון העסק",
  BUSINESS_EDIT_Setting_HEADER: "הגדרות עסק",
  BUSINESS_EDIT_SETTING_MEMBER_SUCESS: "ההרשאה עודכנה בהצלחה",
  BUSINESS_EDIT_STAFF_HEADER: "חברי צוות",
  BUSINESS_MENU_DELETE: "מחיקת עסק",
  BUSINESS_MENU_EDIT: "עריכת עסק",
  BUSINESS_MENU_VIEW: "צפייה בפרויקטים",
  BUSINESS_SETTING_MEMBER_ADD_BUTTON: "שמור הרשאה",
  BUSINESS_SETTING_MEMBER_CANCEL_INVITE: "ביטול הזמנה",
  BUSINESS_SETTING_MEMBER_EMAIL: "אימייל",
  BUSINESS_SETTING_MEMBER_PERMISSION: "הרשאה",
  BUSINESS_SETTING_MEMBER_PERMISSION_EMAIL: "אימייל",
  BUSINESS_SETTING_MEMBER_PERMISSION_MANAGER: "מנהל",
  BUSINESS_SETTING_MEMBER_PERMISSION_MEMBER: "חבר",
  BUSINESS_SETTING_MEMBER_PERMISSION_NAME: "שם",
  BUSINESS_SETTING_MEMBER_PERMISSION_ROLE: "הרשאה",
  BUSINESS_SETTING_MEMBER_PERMISSION_VIEWER: "צופה",
  BUSINESS_SETTING_MEMBER_REMOVE_FROM_STAFF: "הסר חבר צוות",
  BUSINESS_SETTING_MEMBER_RESEND_INVITE: "שליחת הזמנה",
  BUSINESS_SETTING_TRANSACTION_BY: "חבר צוות",
  BUSINESS_SETTING_TRANSACTION_CREDITS: "קרדיטים",
  BUSINESS_SETTING_TRANSACTION_DATE: "תאריך",
  BUSINESS_SETTING_TRANSACTION_FOR: "עבור",
  BUSINESS_SETTING_TRANSACTION_PACKAGE: "חבילה",
  BUSINESS_SETTING_TRANSACTION_STATUS: "סטטוס",
  BUSINESS_SETTING_UPDATE_SUCESS: "הגדרות העסק עודכנו בהצלחה",
  BUSINESS_STAFF_INVITE_CANCEL_SUCESS: "ההזמנה בוטלה בהצלחה",
  BUSINESS_STAFF_INVITE_RESEND_SUCESS: "ההזמנה נשלחה בהצלחה",
  BUSINESS_STAFF_INVITE_SUCESS: "ההזמנה נשלחה בהצלחה",
  BUSINESS_STAFF_REMOVE_SUCESS: "חבר צוות הוסר בהצלחה",
  CHANGE_EMAIL_GENERAL: "עדכון אימייל נכשל. נסה מאוחר יותר",
  CHANGE_PASSWORD_GENERAL: "עדכון הסיסמה נכשל. נסה מאוחר יותר",
  CHANGE_PASSWORD_NO_MATCH: "סיסמאות אינן תואמות",
  CHANGE_PERMISSIONS_GENERAL: "אירעה תקלה",
  CHECK_PASSWORD_INVALID: "סיסמה לא תקינה",
  CLIMATE_A: "אקלים א׳",
  CLIMATE_B: "אקלים ב׳",
  CLIMATE_C: "אקלים ג׳",
  CLIMATE_D: "אקלים ד׳",
  CONTACT_US_EMAIL: "אימייל",
  CONTACT_US_HEADER: "אנחנו כאן בשבילכם!",
  CONTACT_US_HELP: "איך אפשר לעזור?",
  CONTACT_US_HELP_INSTRUCTION: "הדרכה",
  CONTACT_US_HELP_RECOMMENDATION: "המלצה",
  CONTACT_US_HELP_SUPPORT: "תמיכה",
  CONTACT_US_HELP_TOPIC: "נושא",
  CONTACT_US_NAME: "שם מלא",
  CONTACT_US_SEND: "שליחה",
  CONTACT_US_SUCCESS_HEADER: "תודה על פנייתך",
  CONTACT_US_SUCCESS_HEADER1: "נחזור אליך בקרוב",
  CONTACT_US_SUCCESS_MESSAGE: "תודה על פנייתך. נחזור אליך בקרוב.",
  CONTACT_US_TOPIC: "נושא",
  CONTACTUS_EMAIL_REQUIRED: "שדה חובה",
  CONTACTUS_GENERAL: "לא ניתן לשלוח הודעה. נסה מאוחר יותר.",
  CONTACTUS_NAME_REQUIRED: "שדה חובה",
  CONTACTUS_SUCCESS: "תודה! נחזור אליך בקרוב.",
  DELETE_ORGANIZATION_SUCCESS_MESSAGE: "חשבון העסק נמחק בהצלחה!",
  DELETE_REVISION_SUCCESS_MESSAGE: "החלופה נמחקה בהצלחה!",
  DESIGN_BUILDER: "DesignBuilder",
  DROPZONE_PLACEHOLDER: "לחץ או גרור תמונה כאן",
  EDIT_BUSINESS_HEADER_BALANCE: "קרדיטים",
  EDIT_BUSINESS_HEADER_VALID_UNTIL: "בתוקף עד",
  EDIT_BUSINESS_SETTING_ADDRESS: "כתובת",
  EDIT_BUSINESS_SETTING_ADDRESS_HELPER_TEXT: "כתובת זו תופיע בדוחות שלך",
  EDIT_BUSINESS_SETTING_EMAI_HELPER_TEXT: "אימייל זה יופיע בדוחות שלך",
  EDIT_BUSINESS_SETTING_EMAIL: "אימייל",
  EDIT_BUSINESS_SETTING_HONE_HELPER_TEXT: "טלפון זה יופיע בדוחות שלך",
  EDIT_BUSINESS_SETTING_NAME: "שם העסק",
  EDIT_BUSINESS_SETTING_NAME_HELPER_TEXT: "שם זה יופיע בדוחות",
  EDIT_BUSINESS_SETTING_PHONE: "טלפון",
  EDIT_BUSINESS_SETTING_SAVE_BUTTON: "שמור",
  EDIT_BUSINESS_TABLE_BY: "חבר צוות",
  EDIT_BUSINESS_TABLE_CREDITS: "קרדיט",
  EDIT_BUSINESS_TABLE_DATE: "תאריך",
  EDIT_BUSINESS_TABLE_FOR: "עבור",
  EDIT_BUSINESS_TABLE_STATUS: "סטטוס",
  EDIT_PROJECT_HEADING_1: "עריכת מבנה",
  EDIT_PROJECT_SAVE_BUTTON: "שמור",
  EDIT_REVISION_TITLE: "עריכת חלופה",
  EMAIL_CANNOT_EXISTS_DIFFERENT_ORGANIZATIONS: "אימייל זה משויך לעסק אחר",
  EMAIL_MISMATCH: "כתובות אימייל אינן תואמות",
  FILL_BETA_ORGANIZATION_GENERAL: "אירעה תקלה",
  FORGOT_BTN_TEXT: "איפוס סיסמה",
  FORGOT_EMAIL: "אימייל",
  FORGOT_EMAIL_HELPTEXT: "כתובת אימייל הרשומה שלך",
  FORGOT_EMAIL_INVALID: "אימייל לא תקין",
  FORGOT_EMAIL_NOT_EXIST: "אימייל לא מזוהה",
  FORGOT_EMAIL_REQUIRED: "שדה חובה",
  FORGOT_GENERAL: "לא ניתן לשחזר משתמש",
  FORGOT_HEADER: "שחזור סיסמה",
  FORGOT_PASSSWORD_SUCCESS_MESSAGE: "בדוק את תיבת הדואר והספאם שלך למייל",
  FORGOT_SUCCESS: "בדוק את תיבת הדואר והספאם שלך למייל",
  FORGOT_SUCCESS_HEADER: "בדוק את תיבת הדואר והספאם שלך למייל",
  GENERAL_ERROR: "משהו השתבש! נסה שוב מאוחר יותר.",
  HEADER_PROFILE_MENU_LOGOUT: "התנתק",
  HEADER_PROJECT_LIST: "פרויקטים",
  IMAGE_SIZE_ERROR: "התמונה גדולה מדי, מקסימום KB500",
  INVALID_CREDENTIALS: "הסיסמה הנוכחית שגויה",
  INVITE_EMAIL_ALREADY_INVITED: "כבר הוזמנת",
  INVITE_EMAIL_INVALID: "אימייל לא תקין",
  INVITE_EMAIL_REQUIRED: "שדה חובה",
  INVITE_GENERAL: "לא ניתן להזמין משתמש, נסה שוב",
  INVITE_SIGNUP_BUTTON: "הצטרפות חינם",
  INVITE_SIGNUP_CANCEL_BUTTON: "ביטול",
  INVITE_SIGNUP_EMAIL: "אימייל",
  INVITE_SIGNUP_EMAIL_HELPER_TEXT: "אימייל כפי שמוצג בהזמנה",
  INVITE_SIGNUP_EMAIL_RECEIVE: "אני רוצה לקבל עדכונים וטיפים",
  INVITE_SIGNUP_NAME: "שם מלא",
  INVITE_SIGNUP_NAME_HELPER_TEXT: "שם זה יופיע בדוחות",
  INVITE_SIGNUP_PASSWORD: "סיסמה",
  INVITE_SIGNUP_TERMS: "קראתי ואישרתי את",
  INVITE_SIGNUP_TERMS_LINK: "תנאי שימוש",
  INVITED_BY_TEAM: "הוזמן לצוות",
  LOGIN_BTN_TEXT: "התחבר",
  LOGIN_EMAIL: "אימייל",
  LOGIN_EMAIL_HELPTEXT: "כתובת אימייל הרשומה",
  LOGIN_EMAIL_INVALID: "אימייל לא חוקי",
  LOGIN_EMAIL_REQUIRED: "שדה חובה",
  LOGIN_FORGOT_PASSWORD_BTN_TEXT: "? שכחת סיסמה",
  LOGIN_GENERAL: "לא ניתן להתחבר, נסה אחר כך",
  LOGIN_HEADER: "התחברות לדירוג אנרגטי",
  LOGIN_INVALID_CREDENTIALS: "אימייל או סיסמא לא נכונים",
  LOGIN_PASSWORD: "סיסמה",
  LOGIN_PASSWORD_REQUIRED: "שדה חובה",
  LOGIN_QUOTE: "אפליקציה דירוג אנרגטי",
  LOGIN_REGISTER_BTN_TEXT: "הרשמה חינם",
  MENU_ADMIN_DASHBOARD: "חשבונות עסקיים",
  MENU_ADMIN_TICKETS: "קריאות",
  MENU_BUSINESS: "חשבון העסק",
  MENU_CONTACT: "צור קשר",
  MENU_DASHBOARD: "פרויקטים",
  MENU_EDIT_USER: "אזור אישי",
  MENU_HOW_IT_WORKS: "איך זה עובד",
  MENU_PRICING: "מחירון",
  NEW_EMAIL_DONT_MATCH: "אימייל לא תואם",
  NEW_REVISION_GREATER_100: "מסה חישובית של קירות החוץ > 100 ק''ג למ''ר",
  NEW_REVISION_GUSH: "העברות תרמית של גשרים תרמיים בקירות חוץ (וט למ''ר.קלווין)",
  NEW_REVISION_INFO: "מבנים חדשים נדרשים לעמוד בכל תנאי סף של ת''י 5282. עמידה בת''י 1045 לפי טבלה 1 כרוכה בהגדרת חומרים עם מוליכות וצפיפות. חישוב H של בית בודד או דו-משפחתי הינו חלקי.",
  NEW_REVISION_INFO_1: "נתונים לחישוב H לפי ת״י 5280",
  NEW_REVISION_LESS_100: "מסה חישובית של קירות החוץ ≤ 100 ק''ג למ''ר",
  NEW_REVISION_PARTITION: "אחוז גשרים תרמיים בקירות חוץ (0-100)",
  NEW_REVISION_SIMULATOR_NAME: "Simulator Name",
  NEW_REVISION_TEXTAREA_PLACEHOLDER: "תיאור החלופה (לא יופיע בדוחות)",
  NEW_REVISION_TITLE: "חלופה חדשה",
  NEWPASS_GENERAL: "לא ניתן לשנות סיסמא",
  NO_PROJECT_HEADING_1: "אין לך עדיין פרויקטים",
  NO_PROJECT_HEADING_2: "כדי להתחיל, לחץ על בניין חדש",
  NO_REVISION_HEADING_1: "אין לך עדיין חלופות",
  NO_REVISION_HEADING_2: "כדי להתחיל, לחץ על חלופה חדשה",
  NO_SEARCHRESULT_HEADING_1: "לא נמצאו התאמות",
  ORG_REGISTRATION_SUCCESS_MESSAGE: "תודה! נחזור אליך בקרוב",
  ORGANISATION_DELETE_NO: "ביטול",
  ORGANISATION_DELETE_POPUP_DESCRIPTION: "אתה בטוח רוצה למחוק חשבון העסק?",
  ORGANISATION_DELETE_POPUP_TITLE: "Business Account Name",
  ORGANISATION_DELETE_YES: "מחיקה",
  PASSWORD_MISMATCH: "סיסמאות לא תואמות",
  PAY_TOPUP_GENERAL: "ביצוע העסקה נכשל עקב בעיה בשרת",
  PIPE_DOWNLOAD_OUTPUT_GENERAL: "אירעה תקלה",
  PRICING_DISABLED_TEXT: "בקרוב לרכישה",
  PRICING_HEADER_1: "חוסכים לכם זמן וכסף בכל פרויקט",
  PRICING_HEADER_2: "סימולציות ממוחשבות בענן והפקת דוחות לכל מבנה",
  PRICING_MONTHLY_PLAN: "רכישה",
  PRICING_PLANS_FAILED: "אירעה תקלה",
  PRICING_YEARLY_PLAN: "רכישה",
  PRIVATE_AREA_EMAIL_UPDATE_SUCESS: "אימייל עודכן בהצלחה",
  PRIVATE_AREA_PASSWORD_UPDATE_SUCESS: "סיסמה עודכנה בהצלחה",
  PRIVATE_AREA_PERSONAL_INFO_UPDATE_SUCESS: "פרטים אישיים עודכנו בהצלחה",
  PROJECT_CANNOT_DELETE_REVISIONS_SUCCESS: "לא ניתן למחוק מבנה עם דוחות",
  PROJECT_CHANGE_COLOR: "שנה צבע",
  PROJECT_CITY_REQUIRED: "שדה חובה",
  PROJECT_CLIMATE_INVALID: "אקלים לא חוקי",
  PROJECT_CLIMATE_REQUIRED: "שדה חובה",
  PROJECT_DELETE: "מחיקת מבנה",
  PROJECT_DELETE_GENERAL: "לא ניתן למחוק את המבנה, נסה אחר כך",
  PROJECT_DELETE_SUCCESS: "המבנה נמחק בהצלחה",
  PROJECT_DELETE_UNAUTHORIZED: "לא מורשה למחוק את המבנה",
  PROJECT_EDIT_MENU: "עריכת מבנה",
  PROJECT_GENERAL: "לא ניתן לשמור את המבנה, נסה אחר כך",
  PROJECT_IMAGE_TOO_BIG: "התמונה גדולה מדי, מקסימום KB500",
  PROJECT_LIST_TABLE_ALTERNATIVES: "חלופות",
  PROJECT_LIST_TABLE_CLIMATE: "אקלים",
  PROJECT_LIST_TABLE_DATE: "תאריך",
  PROJECT_LIST_TABLE_NAME: "שם",
  PROJECT_LIST_TABLE_TYPE: "סוג",
  PROJECT_MENU_DELETE: "מחיקת מבנה",
  PROJECT_MENU_EDIT: "עריכת מבנה",
  PROJECT_MENU_VIEW: " צפייה במבנה",
  PROJECT_NAME_REQUIRED: "שם חובה",
  PROJECT_NAME_TOO_SHORT: "שם המבנה קצר מדי!",
  PROJECT_TYPE_INVALID: "סוג לא חוקי",
  PROJECT_TYPE_REQUIRED: "שדה חובה",
  PROJECTS_ADD_BUTTON: "מבנה חדש",
  PROJECTS_SEARCH_PLACEHOLDER: "חיפוש",
  PROJECTS_TITLE: "פרויקטים",
  REGISTER_ALLOW_POLICY: "נא להסכים למדיניות",
  REGISTER_COMPANY_EMAIL_INVALID: "אימייל לא תקין",
  REGISTER_COMPANY_EMAIL_REQUIRED: "שדה חובה",
  REGISTER_COMPANY_GENERAL: "לא ניתן לרשום חשבון העסק, נסה אחר כך",
  REGISTER_COMPANY_NAME_REQUIRED: "שדה חובה",
  REGISTER_COMPANY_PHONE_REQUIRED: "שדה חובה",
  REGISTER_EMAIL_IN_USE: "אימייל כבר בשימוש",
  REGISTER_EMAIL_INVALID: "אימייל לא תקין",
  REGISTER_EMAIL_INVALID_INVITATION: "אימייל לא תואם את ההזמנה",
  REGISTER_EMAIL_REQUIRED: "שדה חובה",
  REGISTER_GENERAL: "לא ניתן ליצור משתמש, נסה אחר כך",
  REGISTER_NAME_REQUIRED: "שדה חובה",
  REGISTER_NAME_TOO_SHORT: "השם קצר מדי",
  REGISTER_PASSWORD_REQUIRED: "שדה חובה",
  REGISTER_PASSWORD_TOO_SHORT: "הסיסמה קצרה מדי, לפחות 6 תווים",
  RESET_GENERAL: "פג תוקף",
  RESET_PASSWORD_NO_MATCH: "סיסמאות לא תואמות",
  RESET_PASSWORD_TOO_SHORT: "הסיסמה קצרה מדי, לפחות 6 תווים",
  REVISION_ADD_DSB_TO_TICKET_USER_TITLE: "נא לצרף קובץ DesignBuilder על מנת לפתור את הבעיה",
  REVISION_ADD_MISSING_FILE: "גרור קובץ או לחץ כאן",
  REVISION_ADD_SUCCESSFULL: "החלופה נוספה בהצלחה",
  REVISION_BRIDGE_REQUIRED: "שדה חובה",
  REVISION_CHANGED_GENERAL: "אירעה תקלה",
  REVISION_CHECKED_STATUS_BUTTON_TEXT: "הרצת סימולציה",
  REVISION_CREATED_BY_COLUMN: "חבר צוות",
  REVISION_CREDIT_ADD_DESCRIPTION: "הוסף קרדיטים כדי להפיק דוחות",
  REVISION_CREDIT_ADD_NO: "ביטול",
  REVISION_CREDIT_ADD_TITLE: "הוספת קרדיטים",
  REVISION_CREDIT_ADD_YES: "מחירון",
  REVISION_DAYLIGHTING_FORBIDDEN: "אין להוסיף חיישני תאורה בבניין מגורים או אחסון תיירותי",
  REVISION_DELETE_GENERAL: "לא ניתן למחוק את החלופה",
  REVISION_DELETE_NO: "ביטול",
  REVISION_DELETE_POPUP_DESCRIPTION: "אתה בטוח רוצה למחוק חלופה זו?",
  REVISION_DELETE_POPUP_TITLE: "מחיקת חלופה",
  REVISION_DELETE_UNAUTHORIZED: "אינך מורשה למחוק את החלופה הזו",
  REVISION_DELETE_YES: "מחיקה",
  REVISION_DENSITY_REQUIRED: "שדה חובה",
  REVISION_DUPLICATE_ZONE_NAMES: "יחידות תכנון צריכות שמות ייחודיים",
  REVISION_EDIT_NEED_ANOTHER_FILE: "כדי לעדכן עדכון שנכשל אתה זקוק לקובץ אחר",
  REVISION_ERROR_STATUS_BUTTON_TEXT: "פתח שגיאה",
  REVISION_File_COLUMN: "קובץ",
  REVISION_FILE_INVALID: "הקובץ אינו תקין, עיין בהוראות",
  REVISION_FILE_REQUIRED: "קובץ חובה",
  REVISION_GENERAL: "לא ניתן לשמור חלופה",
  REVISION_H_REPORT_COLUMN: "H",
  REVISION_INDEX_COLUMN: "#",
  REVISION_INVALID_PATTERN_ZONE_NAMES: "שמות לא תקינים",
  REVISION_MENU_DELETE_REVISION: "מחיקת חלופה",
  REVISION_MENU_DOWNLOAD_1045_REPORT: "הורדת דוח 1045",
  REVISION_MENU_DOWNLOAD_3D_REPORT: "הורדת מודל תלת מימד",
  REVISION_MENU_DOWNLOAD_CSV_FILE: "הורדת csv report",
  REVISION_MENU_DOWNLOAD_DETAILED_REPORT: "הורדת דוח מפורט",
  REVISION_MENU_DOWNLOAD_DSB_MODEL_FILE: "הורדת מודל DB",
  REVISION_MENU_DOWNLOAD_H_REPORT: "הורדת דוח H",
  REVISION_MENU_DOWNLOAD_OUTPUT_JSON_FILE: "הורדת output.json",
  REVISION_MENU_DOWNLOAD_INPUT_JSON_FILE: "הורדת input.json",
  REVISION_MENU_DOWNLOAD_RATING_REPORT: "הורדת דוח מדבקות",
  REVISION_MENU_DOWNLOAD_RUN_FILE: "הורדת קובץ הרצה",
  REVISION_MENU_EDIT: "עריכת חלופה",
  REVISION_MENU_VIEW_RESULT: "צפייה בתוצאות",
  REVISION_MISSING_COOLING: "יחידת תכנון חייבת להיות עם קירור",
  REVISION_MISSING_HEATING: "יחידת תכנון חייבת להיות עם חימום",
  REVISION_MISSING_ZONES: "אין יחידות מאוקלמות",
  REVISION_NOTES_COLUMN: "הערות",
  REVISION_NOTES_GRADE: "ציון",
  REVISION_NOTES_RATING: "דירוג",
  REVISION_OPEN_TICKET_ACTION: "בחר פעולה",
  REVISION_OPEN_TICKET_CLOSED_FAILED: "הקריאה טופלה עם שגיאה למשתמש",
  REVISION_OPEN_TICKET_CLOSED_FIXED: "הקריאה טופלה בהצלחה",
  REVISION_OPEN_TICKET_CLOSED_FXED: "הקריאה טופלה בהצלחה",
  REVISION_OPEN_TICKET_RE_PROCESS_REVISION: "התחל הרצה שוב",
  REVISION_OPEN_TICKET_REOPEN_REVISION: "קובץ DSB לא נכון, נא להעלות שוב",
  REVISION_OPEN_TICKET_REVISION_ERROR: "ערוך כאן רק במקרה של שגיאה",
  REVISION_PENDING_STATUS_BUTTON_TEXT: "בטיפול",
  REVISION_RATING_GRADE_COLUMN: "r",
  REVISION_RESOLVING_STATUS_ONGOING_TICKET_BUTTON_TEXT: "בטיפול",
  REVISION_RESOLVING_STATUS_OPEN_TICKET_BUTTON_TEXT: "פתיחת קריאה",
  REVISION_RESULT_1045_TABLE: "התנגדות תרמית לפי ת''י 1045 טבלה 1",
  REVISION_RESULT_CALCULATION_H_PLANNING_UNITS: "חישוב H ליחידת תכנון (לא נדרש בבדיקה התפקודית משנת 2024)",
  REVISION_RESULT_DOWNLOAD_BUTTON: "הורדת דוחות",
  REVISION_RESULT_ENERGY_RATING: "דירוג אנרגטי",
  REVISION_RESULT_ENERGY_RATING_PLANNING_UNIT: "דירוג אנרגטי ליחידות תכנון",
  REVISION_RESULT_ESTIMATED_SAVINGS: "חיסכון מוערך",
  REVISION_RESULT_ESTIMATED_SAVINGS_5_YEARS: "עבור 5 שנים",
  REVISION_RESULT_H_REPORT_MAP_FAIL: "נכשל",
  REVISION_RESULT_H_REPORT_MAP_PASS: "עובר",
  REVISION_RESULT_RETURN_BUTTON: "חזרה",
  REVISION_RESULT_UNLOCK_BUTTON: "הפקת דוחות",
  REVISION_RESULT_WEIGHTED_SCORE: "ציון משוקלל",
  REVISION_RESULTS_GENERAL: "לא ניתן להציג תוצאות",
  REVISION_RUN_GENERAL: "לא ניתן להריץ חלופה",
  REVISION_RUN_NOT_ENOUGH_CREDITS: "לא ניתן להריץ חלופה",
  REVISION_SUCCESS_DOWNLOAD_BUTTON_TEXT: "הורדת דוחות",
  REVISION_SUCCESS_TOPUP_BUTTON_TEXT: "הוספת קרדיטים",
  REVISION_SUCCESS_UNLOCK_BUTTON_TEXT: "הפקת דוחות",
  REVISION_TICKET_ADD_SUCCESS: "הקריאה נוצרה בהצלחה",
  REVISION_UNLOCK_DESCRIPTION: "$$1 קרדיטים יירדו מחשבון העסק",
  REVISION_UNLOCK_GENERAL: "לא ניתן להציג חלופה, נדרשים קרדיטים",
  REVISION_UNLOCK_NO: "לא, תודה",
  REVISION_UNLOCK_TITLE: "רוצה להפיק דוחות?",
  REVISION_UNLOCK_YES: "כן, אני רוצה",
  REVISION_UNSUPPORTED_AIRFLOW: "הגדרת אוורור לא מתאימה",
  REVISION_UPDATE_SUCCESSFULL: "החלופה עודכנה בהצלחה",
  REVISION_UPDATED_DATE: "תאריך עדכון",
  REVISION_UPLOAD_GENERAL: "אירעה תקלה",
  REVISION_VALIDATING_STATUS_BUTTON_TEXT: "בבחינה",
  REVISIONS_ADD_BUTTON: "חלופה חדשה",
  REVISIONS_DROP_FILE_PLACEHOLDER: "גרור קובץ או לחץ כאן",
  SAVE_ORGANIZATION_GENERAL: "לא ניתן לשמור חשבון העסק",
  SAVE_USER_GENERAL: "לא ניתן לשמור משתמש",
  SIGNUP_ADDRESS: "כתובת",
  SIGNUP_ADDRESS_HELPTEXT: "כתובת זו תופיע בדוחות שלך",
  SIGNUP_BUSINESS_NAME: "שם העסק",
  SIGNUP_BUSINESS_NAME_HELPTEXT: "שם זה יופיע בדוחות",
  SIGNUP_EMAIL: "אימייל",
  SIGNUP_EMAIL_HELPTEXT: "אימייל זה יופיע בדוחות שלך",
  SIGNUP_HEADER: "השאר פרטי עסק",
  SIGNUP_HEADER2: "כדי ליצור חשבון חינם",
  SIGNUP_INVITE_HEADER: "צור משתמש",
  SIGNUP_INVITE_HEADER2: "כדי ליצור חשבון חינם",
  SIGNUP_PHONE:'טלפון',
  SIGNUP_PHONE_BUTTON:'	שלח פרטים',
  SIGNUP_PHONE_HELPTEXT:'	טלפון זה יופיע בדוחות שלך',
  TERMS_OF_USE:'תנאי שימוש',
  TERRAIN_OPTION_CITY:'עירונית',
  TERRAIN_OPTION_COUNTRY:'כפרית',
  TERRAIN_OPTION_SUBURBS:'פרברית',
  TICKET_MISSING_SUCCESS_FILES:'	כל הקבצים חובה למקרה הצלחה',
  TOKEN_FORGOT_EXPIRED:'	אסימון פג תוקף, pls לאפס שוב',
  TOKEN_FORGOT_INVALID:'	אסימון שגוי, נסה לאפס את הסיסמה שלך',
  TOKEN_GENERAL:'	לא ניתן לאמת סיסמא חדשה',
  TOKEN_INVITE_INVALID:'אסימון שגוי, נסה שוב להזמין אותך',
  TYPE_EDUCATIONAL:'חינוך',
  TYPE_HOTEL:'אכסון תיירותי',
  TYPE_OFFICE:'משרדים',
  TYPE_OFFICE_CORE_SHELL:'משרדים גרעין ומעטפת',
  TYPE_RESIDENTIAL:'מגורים',
  USER_EDIT_EMAIL_BTN_TEXT:'שמור',
  USER_EDIT_EMAIL_CURRENT_EMAIL:'	אימייל קיים',
  USER_EDIT_EMAIL_CURRENT_EMAIL_HELPER_TEXT:'	אימייל זה יופיע בדוחות שלך',
  USER_EDIT_EMAIL_NEW_EMAIL:'אימייל חדש',
  USER_EDIT_EMAIL_NEW_EMAIL_CONFIRM:'	אימות אימייל חדש',
  USER_EDIT_EMAIL_NEW_EMAIL_CONFIRM_HELPER_TEXT:'אימות כתובת הדואר האלקטרוני שלך תופיע בדוחותך',
  USER_EDIT_EMAIL_NEW_EMAIL_HELPER_TEXT:'	כתובת הדואר האלקטרוני שלך תופיע בדוחותך',
  USER_EDIT_EMAIL_UPDATE_HEADER:'	עדכון אימייל',
  USER_EDIT_HEADER:'אזור אישי',
  USER_EDIT_PASSWORD_CURRENT_PASS:'סיסמה נוכחית',
  USER_EDIT_PASSWORD_NEW_PASS:'סיסמה חדשה',
  USER_EDIT_PASSWORD_NEW_PASS_CONFIRM:'אימות סיסמה חדשה',
  USER_EDIT_PASSWORD_UPDATE_BTN_TEXT:'שמור',
  USER_EDIT_PASSWORD_UPDATE_HEADER:'עדכון סיסמה',
  USER_EDIT_PI_BTN_TEXT:'שמור',
  USER_EDIT_PI_HEADER:'פרטים אישיים',
  USER_EDIT_PI_MOBILE:'נייד',
  USER_EDIT_PI_MOBILE_HELPER_TEXT:'	נייד לצורך תמיכה בלבד',
  USER_EDIT_PI_NAME:'	שם מלא',
  USER_EDIT_PI_NAME_HELPER_TEXT:'	שם זה יופיע בדוחות',
  USER_EDIT_PI_ROLE:'	תפקיד',
  USER_EDIT_PI_ROLE_HELPER_TEXT:'	תפקיד זה יופיע בדוחות',
  USER_PHONE_REQUIRED:'	שדה חובה',
  USER_ROLE_REQUIRED:'	שדה חובה',
  VALIDATE_INVITE_GENERAL:'	הזמנה לא תקפהת',
  MUI_ROWS_PER_PAGE:'שורות לעמוד :',
  MUI_PAGINATION_RANGE: "{{from}}-{{to}} מתוך {{count}}",
  MUI_PAGINATION_NEXT_PAGE: "לעמוד הבא",
  MUI_PAGINATION_PREVIOUS_PAGE: "לעמוד הקודם",
  ADMIN_DASHBOARD_CREDITS: 'קרדיט',
  ADMIN_DASHBOARD_PURCHASED: 'סה"כ',
  ADMIN_DASHBOARD_TOTAL_PROJECTS: "פרויקטים",
  ADD_PROJECT_VERSION: 'גרסה',
  ADD_PROJECT_CONSTRUCTION: 'חדש או קיים',
  TYPE_CONSTRUCTION_NEW: "מבנה חדש",
  TYPE_CONSTRUCTION_EXIST: "מבנה קיים",
  PROJECT_VERSION_V2: "V2",
  PROJECT_VERSION_V3: "V3",
  REVISION_UNSUPPORTED_AIRFLOW_HEIGHT_SELECTION_FOR_LOCAL_WIND_SPEED_CALCULATION:"אין לשנות נתוני אוורור כאשר משתמשים בCALCULATED VENTILATION",
  REVISION_UNSUPPORTED_AIRFLOW_NETWORK_CONTROL:"אין להשתמש בגרסה זו באוורור מסוג CALCULATED VENTILATION",
  REVISION_UNSUPPORTED_AIRFLOW_NUMBER_OF_SETS_OF_OPENING_FACTOR_DATA:"אין לשנות נתוני אוורור כאשר משתמשים בCALCULATED VENTILATION",
  REVISION_UNSUPPORTED_AIRFLOW_WIND_PRESSURE_COEFFICIENT_TYPE:"אין לשנות נתוני אוורור כאשר משתמשים בCALCULATED VENTILATION",
  REVISION_UNSUPPORTED_COOLING_HEATING_LIMIT:"גודל מערכות לחימום וקירור שונות במבנה ייחוס. ראו הסבר לגבי גרסאות בעמוד איך זה עובד",
  REVISION_UNSUPPORTED_SHADING_DATA:"INCLUDE ALL BUILDINGS IN SHADING CALCS נא לא לסמן",
  REVISION_UNSUPPORTED_VERSION:"קובץ זה אינו מתאים לעבודה עם גרסה זו",
  BUSINESS_SETTING_MEMBER_PERMISSION_MANAGER_DESCRIPTION: "יכול להגדיר את העסק, לרכוש קרדיטים, להוסיף פרויקטים וחלופות",
  BUSINESS_SETTING_MEMBER_PERMISSION_MEMBER_DESCRIPTION: "יכול להוסיף פרויקטים וחלופות",
  BUSINESS_SETTING_MEMBER_PERMISSION_VIEWER_DESCRIPTION: "יכול לראות פרויקטים וחלופות",
  PROJECT_ADD_EDUCATIONAL_WARN: "התקן למבני חינוך בתהליך של רוויזיה. מומלץ להגיש דוח לפי מבני משרדים",
  ADMIN_TICKETS_COMPANY_NAME: "החברה",
  ADMIN_TICKETS_REVISION_ID: "מספר",
  ADMIN_TICKETS_CREATED_BY: "נוצר על ידי",
  ADMIN_TICKETS_PROJECT_NAME: "הפרויקט",
  ADMIN_TICKETS_CREATED_DATE: "תאריך",
  ADMIN_TICKETS_STATUS: "סטטוס",
  ADMIN_OPEN_TICKET_COMPANY_DETAILS: "פרטי החברה",
  REVISION_GRAPH_ADD_DIMENSION: "הוסף מימד",
  REVISION_GRAPH_MODIFY_DIMENSION: "שנה מימד",
  REVISION_ADD_LOADING: "נא להמתין לשמירת הקובץ",
  BUSINESS_CREATE_HEADER : "עסק חדש",
  LAYOUT_RESET_TITLE: "איפוס עמודות",
  LAYOUT_RESET_DESCRIPTION: "רוצה לאפס את המידע שערכת",
  FIELDS_LIMIT_12: "ניתן לבחור עד 12 עמודות"
}
};