import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Button, ButtonProps, useTheme } from '@mui/material';
import React from 'react';

interface ButtonIconProps extends ButtonProps {
	icon?: React.ReactNode;
}

const ButtonIconText: React.FC<ButtonIconProps> = ({ icon, children, ...props }) => {
	const theme = useTheme();
	const isRTL = theme.direction == 'rtl';
	return (
		<Button
			{...props}
			sx={{
				...(isRTL ? { pr: 2, pl: 1 } : { pl: 1 }),
				width: 'auto',
				minWidth:"176px",
				height: "50px",
				/* 	Width: 'auto',
					height: '50px',
					gap:"5px",
					fontSize: '20px',
					...(isRTL ? { pr: 1 } : { pl: 1 }),
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center', */
				...props.sx,
			}}
		>
			{icon || <AddRoundedIcon sx={{ fontSize: '40px', p: 0, m: 0, mr: "0" }} />}
			<Box sx={{
				 display: "flex", alignItems: 'center', justifyContent: "flex-start", fontSize: '20px',flexWrap: "nowrap"
			}}>
				{children}
			</Box>
		</Button>
	);
};

export default ButtonIconText;