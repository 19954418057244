import { IconButton, useTheme } from '@mui/material';


const ButtonIcon = ({ isActive, children, onClick, sx }: { isActive?: boolean; onClick?: (e:any) => void,children: React.ReactNode, sx?: object }) => {
	const theme = useTheme();

	return (
		<IconButton
			onClick={onClick}
			sx={{
				color: isActive ? theme.palette.primary.main : theme.palette.secondary.main, // Couleur de l'icône
				'&:hover': {
					color: isActive ? theme.palette.primary.main : 'black', // Couleur de l'icône au survol
				},
				...sx // Intégration de sx dans les styles
			}}
		>
			{children} 
			</IconButton>
	);
};

export default ButtonIcon;