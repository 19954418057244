import { TableCell, TableCellProps } from '@mui/material';
import React from 'react';
import { RevisionStatus } from 'utils/contracts';

interface CustomTableCellProps extends TableCellProps {
  children?: React.ReactNode;
  disabled?: boolean;
  status?: any;
  collapseMode?:boolean;
  clickable?:boolean;
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({ children, collapseMode, status, disabled = false, clickable = true, width, ...props }) => {
  return (
    <TableCell
      style={{
        ...(collapseMode && {borderBottom: 'none'}),
        ...(disabled && { color: 'rgba(0, 0, 0, 0.38)' }),
        ...(status === RevisionStatus.SUCCESS || clickable ? { cursor: 'pointer' } : { cursor: 'default' }),
        width: width || 'auto',
        minWidth: width || 'auto',
        maxWidth: width || 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...props.style
      }}
      sx={{ height: '54px', py:0, ...props.sx}}
      {...props}
    >
      {children}
    </TableCell>
  );
};

export default CustomTableCell;